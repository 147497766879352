<template>
  <b-modal
    size="lg"
    title-class="font-18"
    hide-footer
    v-model="open"
    @hidden="$emit('hideModal')"
  >
    <FormulateForm v-model="values" name="vendor" @submit="submitHandler">
      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="company_name"
            type="text"
            label="Company name"
            placeholder="Company name here"
            validation="required"
            :validationMessages="{ required: 'Company name is required.' }"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="contact_name"
            type="text"
            label="Contact name"
            placeholder="Contact name here"
            validation="required"
            :validationMessages="{ required: 'Contact name required.' }"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            aria-hidden="true"
            name="assigned_tribe"
            :options="tribeAvailable"
            :help="
              loadingTribes
                ? 'Tribe list is loading,Please wait'
                : tribeAvailable.length
                ? ''
                : 'No tribes available'
            "
            label="Tribe"
            type="multi-select"
            placeholder="Please select tribes"
            :multiple="true"
            :taggable="true"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="license_no"
            type="text"
            label="License No."
            placeholder="License No. here"
            validation="required"
            :validationMessages="{ required: 'License number is required.' }"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="email"
            type="email"
            label="Email address"
            placeholder="Email address"
            validation="required|email"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="contact_number"
            type="tel"
            label="Contact"
            placeholder="Phone number here!"
            validation="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="local_address"
            type="text"
            label="Local address"
            placeholder="Local address here"
            validation="required"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="country"
            :options="countries"
            placeholder="Select a country"
            label="Country"
            type="select"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="city"
            type="text"
            placeholder="Please enter city"
            label="City"
            validation="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6" v-if="!vendorSelected">
          <FormulateInput
            name="password"
            type="toggable-password"
            label="Password"
            placeholder="Your password"
            :validation="vendorSelected ? null : 'required'"
          />
        </div>
      </div>
      <div class="mt-5 bank-details-vendor">
        <strong> Bank Details </strong>

        <div class="row">
          <div class="col-md-6">
            <FormulateInput
              name="account_name"
              type="text"
              label="Account name"
              placeholder="Account name"
              validation="required"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput
              name="bank_name"
              type="text"
              label="Bank name"
              placeholder="Bank name"
              validation="required"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput
              name="iban"
              type="text"
              label="IBAN"
              placeholder="IBAN here"
              validation="required"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput
              name="swift_code"
              type="text"
              label="Swift code"
              placeholder="Swift code here"
              validation="required"
            />
          </div>
        </div>
      </div>

      <div class="right-align mt-4 mb-2">
        <FormulateInput
          type="submit"
          :label="vendorSelected ? 'Update Vendor' : 'Add Vendor'"
        />
      </div>
    </FormulateForm>
  </b-modal>
</template>
<script>
import { vendor } from "@/config/api/vendor";
import { Country} from "country-state-city";
export default {
  data() {
    return {
      open: this.openModal,
      filterdCity: [],
      values: {},
      cities: [],
      tribeAvailable: [],
      loadingTribes: true,
      countries: Country.getAllCountries().map((item) => {
        return {
          label: item.name,
          value: item.isoCode,
        };
      }),
    };
  },
  created() {
    this.loadAvailableTribes();
  },
  mounted() {},

  props: ["openModal", "vendorSelected"],
  watch: {
    openModal(val) {
      this.open = val;
    },
    vendorSelected(val) {
      if (val) {
        if (val.assigned_tribe) {
          val.assigned_tribe.forEach((item) => {
            this.tribeAvailable.push({
              label: item.name,
              value: item._id,
            });
          });
        }



        const data = { ...val };

        if (data.assigned_tribe) {
          data["assigned_tribe"] = data.assigned_tribe.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
        }

   
        // Parsing bank details
        data.iban = (data.bank_details && data.bank_details.iban) || null;
        data.account_name =
          (data.bank_details && data.bank_details.account_name) || null;
        data.bank_name =
          (data.bank_details && data.bank_details.bank_name) || null;
        data.swift_code =
          (data.bank_details && data.bank_details.swift_code) || null;
        delete data["bank_details"];

        this.values = data;
      }
    },
  },
  methods: {
    submitHandler(data) {
      if (this.vendorSelected) {
        const api = vendor.updateVendor;
        data.assigned_tribe = data.assigned_tribe.map((item) => {
          if (item && item.value) {
            return item.value;
          } else {
            return item;
          }
        });
        api.data = {
          ...data,
          vendorId: data._id,
          type:'admin'
        };
        this.generateAPI(api)
          .then((res) => {
            if (res.data.vendor) {
              this.$bvToast.toast("Updated successfully!", {
                title: "Vendor",
                variant: "success",
                solid: true,
              });
              this.$emit("dataAdded");
              this.$formulate.reset("vendor");
              this.$emit("hideModal");
            } else {
              this.$bvToast.toast(res.data.message, {
                title: "Vendor",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.message, {
              title: "Vendor",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        const api = vendor.createVendor;
        api.data = data;
        this.generateAPI(api)
          .then((res) => {
            if (res.data.vendor) {
              this.$bvToast.toast("Added successfully!", {
                title: "Vendor",
                variant: "success",
                solid: true,
              });
              this.$emit("hideModal");
              this.$emit("dataAdded");
              this.$formulate.reset("vendor");
            } else {
              this.$bvToast.toast(res.data.message, {
                title: "Vendor",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.message, {
              title: "Vendor",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
    loadAvailableTribes() {
      this.loadingTribes = true;
      const api = vendor.getAvailableTribe;
      this.generateAPI(api)
        .then((res) => {
          const arr = [];
          for (let i = 0; i < res.data.tribes.length; i++) {
            arr.push({
              label: res.data.tribes[i].name,
              value: res.data.tribes[i]._id,
            });
          }

          this.tribeAvailable = arr;
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loadingTribes = false;
        });
    },

   
  },
};
</script>

<style>
.bank-details-vendor {
  border: 1px dashed;
  padding: 12px;
  border-radius: 12px;
  padding-bottom: 18px;
}

.multiselect__single {
  background: #f1f1f1 !important;
}
</style>
