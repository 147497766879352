<template>
  <div class="card py-2" style="min-height: 212px">
    <div class="card-body" v-if="data">
      <b-dropdown
        right
        toggle-class="arrow-none card-drop"
        class="float-right"
        variant="white"
        style="margin-right: -12px"
      >
        <template v-slot:button-content>
          <i class="ri-settings-5-line"></i>
        </template>
        <b-dropdown-item @click="$emit('editVendor', data)"
          >Edit</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('deleteVendor', data._id)">
          Delete</b-dropdown-item
        >
        <b-dropdown-item @click="$emit('changePassword', data._id)"
          >Change Password</b-dropdown-item
        >
     
      </b-dropdown>
      <div style="color: #e83e8c; font-size: 14px">
        <strong> {{ data.company_name }}</strong>
      </div>
      <div style="font-size: 0.84rem" class="mt-3">
        <div class="pb-2">
          <strong> {{ data.email }}</strong>
        </div>

        <div class="d-flex justify-content-between mb-2">
          <div>Contact</div>
          <div>
            {{ data.contact_number }}
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div>License No.</div>
          <div>
            {{ data.license_no }}
          </div>
        </div>
        <div class="d-flex justify-content-between mb-2">
          <div>Assigned Tribe</div>
          <div v-if="data.assigned_tribe && data.assigned_tribe.length">
            <div
              v-for="tribe in data.assigned_tribe"
              :key="tribe._id"
              class="tribe-name-vendor"
            >
              {{ tribe.name }}
            </div>
          </div>
          <div v-else>Not Assigned</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
.tribe-name-vendor {
  background-color: #f3f3f3;
  padding: 2px 6px;
  border-radius: 6px;
  margin-bottom: 4px;
  font-size: 11px;
  font-weight: 700;
}
</style>
