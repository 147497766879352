<template>
  <b-modal id="password-reset" size="sm" hide-footer>
    <FormulateForm :values="values" @submit="submitHandler">
      <FormulateInput
        name="password"
        type="password"
        label="Password"
        placeholder="New password"
        validation="required"
      />
      <FormulateInput
        name="password_confirm"
        type="password"
        label="Confirm new password"
        placeholder="Confirm password"
        validation="required|confirm"
        validation-name="Confirmation"
      />
      <FormulateInput
        class="float-right"
        type="submit"
        label="Change Password"
      />
    </FormulateForm>
  </b-modal>
</template>

<script>
import { auth } from "@/config/api/auth";
export default {
  props: ["id"],
  data() {
    return {
      values: {},
    };
  },
  methods: {
    submitHandler(val) {
      this.changePassword(val.password);
    },
    changePassword(password) {
      const api = auth.changePassword;
      api.data = {
        vendorId: this.id,
        password: password,
      };
      this.generateAPI(api).then((res) => {
        console.log(res.data);
        this.$bvToast.toast('Password reset successfully', {
          title: "Password change",
          variant: "success",
          solid: true,
        });
        this.$emit('passwordChanged')
      });
    },
  },
};
</script>

<style>
</style>